@import "bourbon/bourbon";

@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	position: absolute;
}

@mixin widthheight ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
}

@mixin widthheightboth ($both: auto) {
	width: $both;
	height: $both;
}

@mixin widthheightcentered ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
	$halfwid:($wid/2)*-1;
	$halfheight:($hei/2)*-1.025;
	margin: $halfheight 0 0 $halfwid;
}

@mixin widthheightvertcenter ($wid: auto, $hei: auto) {
	width: $wid;
	height: $hei;
	$halfheight:($hei/2)*-1.025;
	margin-top: $halfheight;
}

@mixin pseudofill() {
	content: '';
	position: absolute;
	top: 0; left: 0;
	@include widthheightboth(100%);
}

@mixin absolutefill() {
	position: absolute; top: 0; left: 0;
	@include widthheightboth(100%);
}

@mixin all-text-white() {
	a, p, h1,h2,h3,h4,h5,h6,li {
		color: $bgwhite;
	}
	h4::before {
		background-color: $bgwhite;
	}
}

@mixin expand-icon-hover() {
	span {
		&::before {
			@include transform(translate3d(-3px, -3px, 0) scaleY(3));
		}
		&::after {
			@include transform(translate3d(-3px, -3px, 0) scaleX(3));
		}
	}
}

// EASING
$fluid: .7,.3,0,1;
$fluidless: .5,.5,0,1;
$smootheaseout: .62,.02,.34,1;
$intenseeaseout: 1,0,0,1;
$lessintenseperfect: .55,0,0.45,1;
$suckitin: .38,-0.32,.89,.34;
$smoothswing: 0.4, 0.3, 0.1, .4;
$gentleeasein: .5,0,.57,1;
$lineareaseout: .45,.65,.64,1;
$sweepinginout: .52,.01,.16,1;
$lineareasein: .52,.7,.41,1;
$gentleout: .11,.62,.3,.98;
$sweetout: .19,1,.22,1;
$bouncey: .1,.55,.29,1.44;

$bgwhite: rgb(242,242,242);
$nurture-black: rgb(39,40,46);

$project-color: rgb(200,200,200);

$mobile-desktop: 700px;
$mobile-small: 500px;
$laptop-desktop: 1200px;
$large-desktop: 1600px;
$giant-desktop: 2100px;

body, html {
	height: 100%; width: 100%;
	background-color: $bgwhite;
	overflow: hidden;
}

.loading-gif {
	@include widthheightcentered(4vw, 4vw);

	&::after {
		@include pseudofill();
		@include transition(0.21s cubic-bezier($sweepinginout));
		background: url('/img/svg/nurture-black.svg') no-repeat center center / 100%;
		@include transform(scale(0));
	}
}

.preloader-wrap {
	@include transition(0.65s cubic-bezier($sweepinginout));
	@include transform(translateX(0vw));
	@include transform-origin(100% 0);
	overflow: hidden;
	.loading-gif {
		@include transition(0.65s cubic-bezier($sweepinginout));
		@include transform(translateX(0vw));
		.circle {
			position: absolute; top: 50%; left: 50%;
			@include widthheightboth(16vw);
			margin: -8.1vw 0 0 -8.1vw;

			// @include transform(scale(0));
			opacity: 0;
			@include transition(0.4s 0.1s);

			&::before, &::after {
				@include pseudofill();
				border-radius: 100%;
				// margin: -1px 0 0 -1px;
			}
			&::after {
				// margin: -3px 0 0 -3px;
				// border: 6px solid transparent;
				// border-left: 6px solid rgba(#fff,1);
				// border-bottom:6px solid rgba(#fff,1);
				background: url('/img/svg/preload-line-top.svg') no-repeat center center / 100%;
				@include animation(rotateforever 1.25s forwards infinite cubic-bezier($smoothswing));
			}
			&::before {
				// border: 1px solid transparent;
				// border-right: 1px solid rgba($nurture-black,1);
				// border-top: 1px solid rgba($nurture-black,1);
				background: url('/img/svg/preload-line.svg') no-repeat center center / 100%;
				@include animation(rotateforever 0.8s forwards infinite cubic-bezier($smoothswing));
			}
		}

	}
	.preloader-bg {
		background: $bgwhite;
	}
	&[data-preloader-on="on"] {
		.loading-gif::after {
			@include transform(scale(1));
		}
		.circle {
			opacity: 1;
		}
	}
	&[data-active="off"] {
		pointer-events: none;
		@include transform(translateX(100vw));
		.loading-gif {
			@include transform(translateX(-100vw));
		}
	}
}

div {
	position: relative;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin: 0; padding: 0;
	}
}

.perch-content {
	display: none;
}

ul, ol {
	list-style: none;
}

a, p, h1,h2,h3,h4,h5, body {
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "aktiv-grotesk";
	color: $nurture-black;
	position: relative;
	font-variant-ligatures: discretionary-ligatures;
}

p, h1,h2,h3,h4,h5, h6, body {
	margin: 0;
	font-size: 1em;
	line-height: 1.05em;
	font-weight: normal;
}

h1 {
	font-size: 7.5vw;
	font-family: "abril-fatface";
	line-height: 0.95em;
	padding-top: 0.3em;
	// margin: 0.3em 0 0 -0.06em;
	padding-bottom: 0.35em;
	@media only screen and (max-width: $mobile-desktop) {
		font-size: 55px;
		// margin: 0.6em 0 0.45em -0.05em;
	}
	&.medium {
		font-size: 5.1vw;
		// padding-bottom: 0.35em;
		@media only screen and (max-width: $mobile-desktop) {
			font-size: 40px;
		}
	}
	&.small {
		font-size: 4.2vw;
		@media only screen and (max-width: $mobile-desktop) {
			font-size: 2.5em;
		}
		// padding-bottom: 0.3em;
	}
	&.gigantic-type {
		font-size: 48vw;
		text-align: center;
		padding-top: 0;
		padding-bottom: 0;
		letter-spacing: -0.03em
	}
}

h2 {
	font-size: 3.5vw;
	font-family: "abril-fatface";
	line-height: 1.1em;
	padding-top: 0.1em;
	margin: 1.5em 0 0.6em;
	@media only screen and (max-width: $mobile-desktop) {
		font-size: 24px;
	}
}

h3 {
	font-size: 0.95em;
	@media only screen and (min-width: 1500px) {
		font-size: 1.05em;
	}
	font-family: "abril-display";
	line-height: 1.3em;
	padding-bottom: 0.3em;
}

h4 {
	font-weight: 900;
	text-transform: uppercase;
	font-size: 7px;
	letter-spacing: 0.26em;
	margin-bottom: 1em;
	line-height: 1.3em;
	@media only screen and (min-width: 1500px) {
		font-size: 8px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0.6em;
		left: -12px;
		height: 1px;
		background-color: $nurture-black;
		width: 7px;
	}
}

h5 {
	font-weight: 600;
	font-size: 9px;
	@media only screen and (max-width: $mobile-desktop) {
		font-size: 0.6em;
	}
	@media only screen and (min-width: 1500px) {
		font-size: 10px;
	}
	letter-spacing: 0.02em;
	line-height: 1.55em;
	margin-bottom: 2.2em;
}

h6 {
	font-weight: 700;
	// text-transform: uppercase;
	font-size: 1.15vw;
	@media only screen and (max-width: $mobile-desktop) {
		font-size: 0.75em;
	}
	letter-spacing: 0.02em;
	padding-bottom: 1.25em;
	padding-top: 0.5em;
	// margin-top: 2em;
	line-height: 1.4em;
}

p {
	line-height: 1.5em;
	font-size: 0.8em;
	@media only screen and (min-width: 1600px) {
		font-size: 0.95em;
	}
	font-weight: 500;
	letter-spacing: 0.01em;
	margin-bottom: 0.9em;

	color: rgba($nurture-black, 0.92);
	strong {
		font-weight: 700;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			background-color: rgba($nurture-black,0.15);
			height: 1px;
		}
	}
	&.story-intro {
		font-size: 2.4vw;
		@media only screen and (max-width: $mobile-desktop) {
			font-size: 1.3em;
		}
		font-family: "abril-display";
		line-height: 1.3em;
		font-weight: normal;
		letter-spacing: -0.01em;
		padding-top: 1.5em;
		margin-top: 1.5em;
		margin-bottom: 1em;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			width: 10%;
			height: 1px;
			background-color: $project-color;
			left: 0;
		}
		strong {
			font-weight: normal;
			&::after {
				bottom: 2px;
			}
		}
	}

	&.intro {
		font-size: 1.6vw;
		margin-bottom: 1.2em;
		@media only screen and (max-width: $laptop-desktop) {
			font-size: 1.1em;
		}
		font-family: "abril-display";
		line-height: 1.3em;
		font-weight: normal;
		letter-spacing: -0.01em;
		a {
			font-weight: normal;
		}
	}

	a {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		margin-bottom: 0; padding-bottom: 0;
	}

}

a {
	display: inline-block;
	font-weight: 600;
	font-size: 0.85em;
	line-height: 1.2em;
	margin-bottom: 0.4em;
	padding-bottom: 0.1em;
}

.wrapper {
	background-color: $bgwhite;
	@include transition(0.8s cubic-bezier($sweepinginout) 0.35s);
	&.inactive {
		@include transform(translate3d(0, 25%, 0));
	}
}

img {
	vertical-align: top;
}

// Z-INDEXES SITE WIDE


.preloader-wrap, .page-sweeper {
	z-index: 999999;
}

.logo-wrap {
	z-index: 9999;
}
.nav-menu {
 	z-index: 9950;
}
.social-nav, .disclaimer {
	z-index: 999;
}
.img-gallery {
	z-index: 200;
}
.fixed-elements {
	z-index: 150;
}
.case .content {
	z-index: 100;
}
.left-right {
	z-index: 10;
}

.grid {
	z-index: 2;
}

.pos-a {
	position: absolute;
}

.full-size {
	@include widthheight(100%, 100%);
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

.half-width {
	width: 50%;
}

.viewport-full-height {
	height: 100vh;
}

.pos-a-center {
	position: absolute;
	left: 50%; top: 50%;
}

.pos-a-t-l {
	position: absolute;
	top: 0;
	left: 0;
}

.pos-a-b-l {
	position: absolute;
	bottom: 0;
	left: 0;
}

.pos-a-t-r {
	position: absolute;
	top: 0;
	right: 0;
}

.pos-a-b-r {
	position: absolute;
	bottom: 0;
	right: 0;
}

.pos-f-t-l {
	position: fixed;
	top: 0;
	left: 0;
}

.pos-f {
	position: fixed;
}

.pos-f-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
}

.float-r-list {
	> * {
		float: right;
		margin-left: 1.3vw;
		font-weight: 500;
		font-size: 0.65em;
	}

}

.float-l {
	float: right;
}

.pc-b::before {
	content: "";
}

.pc-a::after {
	content: "";
}

.center-l-t {
	position: absolute;
	left: 50%;
	top: 50%;
}

.overflow-h {
	overflow: hidden;
}

.blend-screen {
	mix-blend-mode: screen;
}

.blend-lighten {
	mix-blend-mode: lighten;
}

.blend-multiply {
	mix-blend-mode: multiply;
}

.blend-burn {
	mix-blend-mode: color-burn;
}

.blend-darken {
	mix-blend-mode: darken;
}

.hover-pointer {
	&:hover {
		cursor: pointer;
	}
}

.section {
	width: 100%;
}

.mob-only {
	display: none;
	@media only screen and (max-width: $mobile-desktop) {
		display: block;
	}
	.touchevents & {
		display: block;
	}
}
.desktop-only {
	display: block;
	@media only screen and (max-width: $mobile-desktop) {
		display: none;
	}
	.touchevents & {
		display: none;
	}
}

.tw {
	background: url('/img/svg/twitter.svg') no-repeat center center / 100%;
}
.lkin {
	background: url('/img/svg/linked-in.svg') no-repeat center center / 100%;
}
.vi {
	background: url('/img/svg/vimeo.svg') no-repeat center center / 100%;
}
.yt {
	background: url('/img/svg/youtube.svg') no-repeat center center / 100%;
}
.fb {
	background: url('/img/svg/facebook.svg') no-repeat center center / 100%;
}
.in {
	background: url('/img/svg/instagram.svg') no-repeat center center / 100%;
}
.mute-icon {
	&::after {
		@include pseudofill();
		@include transition(0.32s cubic-bezier($smootheaseout));
		background: url('/img/svg/mute.svg') no-repeat center center / 80%;
	}

	&[data-muted="muted"]::after {
		opacity: 0.4;
	}
}

.color-overlay {
	@include absolutefill();
	mix-blend-mode: screen;
	@include transition(0.75s cubic-bezier($fluidless) 0.1s);
}

.grey-overlay {
	pointer-events: none;
	background-color: rgba(0,0,0,0.05);
}

.cta {
	text-transform: uppercase;
	font-size: 0.55em;
	font-weight: 900;
	letter-spacing: 0.26em;
	line-height: 1.3em;
	padding: 20px;
	color: #fff;
	width: initial;
	white-space: nowrap;
	text-align: center;

	&[data-color="black"] {
		color: $nurture-black;
		::before, ::after {
			background-color: $nurture-black;
		}
		.expand-icon {
			span {
				&::before, &::after {
					background-color: $nurture-black;
				}
			}
		}
	}

	&.cta-full {
		border: 3px solid $bgwhite;
		padding-left: 25px; padding-right: 25px;

		overflow: hidden;

		&::before, &::after {
			content: '';
			position: absolute;
			width: 8px;
			height: 2px;
			margin-top: -1px;
			top: 50%;
			background-color: $bgwhite;
			@include transform-origin(0% 50%);
			@include transition(0.35s cubic-bezier($sweepinginout));
			-webkit-backface-visibility: hidden;
		}

		&::before {
			right: 8px;
			@include transform(scaleX(0));
		}
		&::after {
			left: 0;
			@include transition-delay(0.18s);
		}
		&[data-color="black"] {
			border-color: $nurture-black;
			&::before, &::after {
				background-color: $nurture-black;
			}
		}
		&:hover {
			&::before {
				@include transition-delay(0.22s);
				@include transition-duration(0.48s);
				@include transform(scaleX(2));
				@include transition-timing-function(cubic-bezier($smootheaseout));
			}
			&::after {
				@include transform(scaleX(2));
				@include transition-delay(0s);
				@include transition-timing-function(cubic-bezier($suckitin));
			}
		}
	}

	&.white-cta {
		background-color: $bgwhite;
		color: $nurture-black;
		&::before {
			background-color: $nurture-black;
		}
	}

	&.cta-icon {
		&.cta-full::after {
			display: none;
		}
		padding: 22px 22px 22px 56px;
	}

	.scroll-icon {
		left: 24px;
		height: 15px;
		margin-top: -10px;
		@include transform(scale(0.8));
		.line {
			background-color: #FFF;
		}
		.arrow {
			&::before, &::after {
				background-color: #FFF;
			}
		}
	}
	.expand-icon {
		position: absolute;
		@include widthheightboth(16px);
		left: 24px;
		top: 50%;
		margin-top: -8px;
	}

	.part-1, .part-2 {
		position: absolute;
		top: 0; left: 0;
		@include widthheightboth(100%);
		&::before {
			content: '';
			width: 100%; height: 2px;
			position: absolute;
			left: 0; top: 0;
			background-color: #FFF;
			@include transform(scaleX(0.08));
			@include transform-origin(0 0);
			@include transition(0.65s cubic-bezier($sweepinginout));
		}
		&::after {
			content: '';
			width: 2px; height: 100%;
			position: absolute;
			left: 0; top: 0;
			background-color: #FFF;
			@include transform(scaleY(0.2));
			@include transform-origin(0 0%);
			@include transition(0.65s cubic-bezier($sweepinginout));
		}
	}
	.part-2 {
		@include transform(rotate(180deg));
	}
	&[data-parts-state="two"] {
		.part-1, .part-2 {
			&::before {
				@include transform(translateX(92%) scaleX(0.08));
			}
			&::after {
				@include transform(translateY(80%) scaleY(0.2));
			}
		}
	}
	&:hover {
		.expand-icon {
			@include expand-icon-hover();
		}
	}
}

.listing {
	margin-bottom: 2em;
	li {
		position: relative;

		a {
			display: block;
			font-family: "abril-fatface";
			font-weight: normal;
			font-size: 2.2vw;
			padding: 1.4em 0 0.9em;
			margin: 0;
			h5 {
				margin-top: 0.6em;
			}
			&::before, &::after {
				content: '';
				position: absolute; bottom: 0; left: 0;
				width: 100%;
				background-color: rgba($nurture-black, 0.14);
				height: 1px;
			}
			&::after {
				background-color: rgba($nurture-black, 0.7);
				@include transform(scaleX(0));
				@include transform-origin( 0 0 );
				@include transition(0.67s cubic-bezier($fluid));
			}
		}
		a:hover {
			&::after {
				@include transform(scaleX(1));
			}
		}
	}
	&.double-column {
		@include display(flex);
		@include flex-direction(row);
		@include flex-wrap(wrap);
		li {
			width: 45%;
			margin: 0 4% 0 0;
		}
	}
}

.widow-killer {
	display: inline-block;
}

.viewport-full-size {
	@include widthheight(100vw, 100vh);
}

.close {
	@include widthheightboth(5vw);
	margin-top: -3vw;
	@include transition(0.25s cubic-bezier($smootheaseout));
	@media only screen and (max-width: $mobile-desktop) {
		@include widthheightboth(26px);
	}
	span {
		@include widthheightboth(100%);
		position: absolute;
		top: 0%; left: 0;
		&::before, &::after {
			content: '';
			width: 100%;
			height: 2px;
			@media only screen and (min-width: $laptop-desktop) {
				height: 3px;
			}
			background-color: #fff;
			position: absolute;
			top: 50%; left: 0;
			@include transition(0.34s cubic-bezier($sweepinginout));
			-webkit-backface-visibility: hidden;
		}
		&:nth-child(1) {
			&::before {
				@include transform(rotate(45deg) translateX(0) scaleX(1));
				@include transition-delay(0.12s);
			}
			&::after {
				@include transform(rotate(-45deg) translateX(0) scaleX(1));
				@include transition-delay(0.22s);
			}
		}

		&:nth-child(2) {
			&::before, &::after {
				// background-color: rgba(#fff, 0);
			}
			&::before {
				@include transform(rotate(45deg) translateX(-70%) scaleX(0));
			}
			&::after {
				@include transform(rotate(-45deg)  translateX(-70%) scaleX(0));
			}
		}
	}
	&:hover span {
		&:nth-child(1) {
			&::before, &::after {
				// background-color: rgba(#fff, 0);
				@include transition-delay(0s);
			}
			&::before {
				@include transform(rotate(45deg) translateX(70%) scaleX(0));
			}
			&::after {
				@include transform(rotate(-45deg) translateX(70%) scaleX(0));
			}
		}
		&:nth-child(2) {
			&::before, &::after {
				// background-color: rgba(#fff, 1);
			}
			&::before {
				@include transform(rotate(45deg) translateX(0) scaleX(1));
				@include transition-delay(0.22s);
			}
			&::after {
				@include transform(rotate(-45deg) translateX(0) scaleX(1));
				@include transition-delay(0.32s);
			}
		}
	}
}

// .circle-icon {
// 	@include widthheightboth(38px);
// 	bottom: 3vw; left: 3vw;
// 	-webkit-backface-visibility: hidden;
// 	@include transition(0.25s cubic-bezier($smootheaseout));
// 	span {
// 		@include absolutefill();
// 		-webkit-backface-visibility: hidden;
// 		overflow: hidden;
// 		&::before, &::after {
// 			@include pseudofill();
// 			@include transition(0.34s cubic-bezier($sweepinginout));
// 			-webkit-backface-visibility: hidden;
// 		}
// 	}
// 	&:hover {
// 		&::before {
// 			@include transform(scale(1));
// 		}
// 	}
// 	&.circle-larger {
// 		@include widthheightboth(55px);
// 	}
// }



.page-sweeper {
	display: none;

	div {
		@include absolutefill();
		@include transform(translateX(-100vw));
		@include transform-origin(100% 0);
		background-color: $bgwhite;
		@include transition(transform 0.55s cubic-bezier($fluid) 0.25s);
	}

	&.page-sweeper-under {
		div {
			background-color: $nurture-black;
			@include transition(transform 0.65s cubic-bezier($sweepinginout));
		}
		body.cases &, body.case & {
			mix-blend-mode: multiply;
		}


	}

	&[data-active="on"] {
		div {
			@include transform(translateX(0vw));
		}
	}
}

.cursor-follower {
	@include transition(none !important);
	z-index: 9999;
	pointer-events: none;
	@include transform(translate3d(-200%, -200%, 0));
	div {
		position: absolute;
		top: 0; left: 0;
	}
	.letter-hover {
		@include widthheightboth(18vw);
		position: absolute;
		top: 0; left: 0;
		margin: -9vw 0 0 -9vw;

		// body.about & {
			@include transform(scale(0.5));
		// }

		z-index: 99;

		&::before {
			@include pseudofill();
			background-color: #000;
			border-radius: 20vw;
			@include transform(scale(0));
			@include transition(0.47s cubic-bezier($sweepinginout));
		}
		&[data-active="on"] {
			&::before {
				@include transform(scale(1));
				@include transition(0.45s cubic-bezier($bouncey));
			}
		}
	}
	.cursor-back {
		@include widthheight(50px, 20px);
		span {
			position: absolute;
			top: 0; left: 0;

			@include transition(0.41s cubic-bezier($sweepinginout));
			&:nth-child(1) {
				background-color: #FFF;
				top: 50%;
				width: 100%; height: 3px;
				margin-top: -1px;
				@include transform-origin(100% 0);
				@include transform(scaleX(0) translateX(40%));
			}
			&:nth-child(2) {

				@include widthheightboth(20px);
				@include transform(scale(0));
				@include transition-timing-function(cubic-bezier($smootheaseout));
				&::before, &::after {
					content: '';
					position: absolute;
					background-color: #FFF;
					@include transform-origin(0 50%);
					@include widthheight(100%, 3px);
					top: 50%; left: 0;
				}
				&::before {
					@include transform(rotate(-45deg));
				}
				&::after {
					@include transform(rotate(45deg));
					margin-top: -2px;

				}

			}
		}
		&[data-active="on"] {
			span {
				&:nth-child(1) {
					@include transform(scaleX(1) translateX(0%));
					@include transition-delay(0.12s);
				}
				&:nth-child(2) {
					@include transition-delay(0.32s);
					@include transform(scale(1));
				}

			}
		}
	}

}


// .sound-icon {

// 	span.mute-icon {

// 		@include transform(scale(0.4));

// 		&::before {
// 			background: url('/img/svg/mute.svg') no-repeat center center / 100%;
// 		}
// 		&::after {
// 			@include transform(scale(0));
// 			background: url('/img/svg/mute-white.svg') no-repeat center center / 100%;
// 		}
// 	}
// 	&[data-sound-active="false"] {
// 		span {
// 			&::before {
// 				@include transform(scale(0));
// 			}
// 			&::after {
// 				@include transform(scale(1));
// 			}
// 		}
// 	}
// }

.play-icon {
	@include transform(scale(0.3));
	&::before {
		background: url('/img/svg/play-icon.svg') no-repeat center center / 100%;
	}
}

// .circle-hover {
// 	border-radius: 100%;
// 	background-color: $bgwhite;
// 	@include transition(0.34s cubic-bezier($sweepinginout));
// }

// a[data-circle-active="false"] {
// 	.circle-hover {
// 		@include transform(scale(0));
// 	}
// 	&:hover {
// 		.circle-hover {

// 		}
// 	}
// }

// a[data-circle-active="false"] {
// 	.circle-hover {
// 		&::after {
// 			@include transform(scale(0));
// 		}
// 	}
// }

.expand-icon {
	@include widthheightboth(20px);
	@include transition(0.25s cubic-bezier($smootheaseout));
	span {
		@include widthheightboth(100%);
		position: absolute; top: 0; left: 0;
		&::before, &::after {
			content: '';
			position: absolute;
			background-color: #fff;
			top: 0; left: 0;
			@include transition(0.35s cubic-bezier($sweepinginout));
			-webkit-backface-visibility: hidden;
			@include transform-origin(0 0);
		}
		&::before {
			width: 6px;
			height: 2px;

		}
		&::after {
			width: 2px;
			height: 6px;
		}
		&:nth-child(1) {

		}

		&:nth-child(2) {
			@include transform(rotate(180deg));
			&::before, &::after {
			}
		}

		&:nth-child(3) {
			@include transform(rotateX(180deg));
			&::before, &::after {
							}
		}

		&:nth-child(4) {
			@include transform(rotateY(180deg));
			&::before, &::after {
			}
		}
	}
	&:hover {
		@include expand-icon-hover();
	}
}

.flex-vert-center {
	@include display(flex);
	@include justify-content(center);
	@include flex-direction(column);
	height: 100%;
	margin-top: -0.5%;
}

.flex-float {
	@include display(flex);
	@include flex-direction(row);
	> * {
		width: 100%;
	}
}

.stat {
	font-size: 8vw;
	font-family: "abril-fatface";
	text-align: center;
	line-height: 1.2em;
	width: 100%;
	display: block;
	position: relative;
	i, em {
		font-size: 4vw;
		color: $nurture-black;
	    transform: translateY(-30%);
	    display: inline-block;
	    font-style: normal;
	}
	@media only screen and (max-width: $mobile-desktop) {
		font-size: 4em;
		i {
			font-size: 0.5em;
		}
	}
}

.flex-columns {
	@include display(flex);
	@include flex-direction(row);
	@media only screen and (max-width: $mobile-small) {
		&:not(.gallery-next-prev) {
			display: block;
		}
	}
	> * {
		width: 100%;
	}
	&.para-columns {
		> * {
			position: relative;
			width: 100%;
			&:nth-child(1) {
				margin: 0 5% 0 0;
			}

		}

		li > h3:not(:first-child) {
			margin-top: 1.6em;
		}

		> li:nth-child(1) {
				&::before {
					content: '01';
				}
			}
		> li:nth-child(2) {
			&::before {
				content: '02';
			}
		}
		> li {
			&::before {
				color: $project-color;
				position: absolute;
				top: 1px;
				left: -13px;
				@include transform(rotate(-90deg));
				font-size: 7px;
				font-weight: 800;
				text-align: right;
				font-family: "aktiv-grotesk";
			}
		}
	}

	h5 {
		text-align: center;
	}
	&.stats {
		li {
			padding: 15% 0% 10%;
			border-top: 1px solid rgba(0,0,0,0.05);
		}
	}
}

.scroll-icon-container {
	padding-top: 0.5em;
	position: relative;
	> span {
		top: 50%;
		display: inline-block;
		@include transform(translateY(-50%) translateX(20%));
	}
}

.scroll-icon {
	// left: -9px;

	top: 50%;
	@include widthheight(20px, 20px);
	margin-top: -10px;
	.line {
		top: 0;
		height: 90%;
		background-color: $nurture-black;
		width: 2px;
		position: absolute;
	    left: 50%;
	    margin-left: -1px;
	}
	.arrow {
		position: absolute;
		bottom: 3px;
		left: 25%;
		@include widthheightboth(100%);
		&::before, &::after {
			content: '';
			width: 50%;
			height: 2px;
			position: absolute;
			bottom: 0;
			background-color: $nurture-black;
		}
		&::before {
			@include transform(rotate(45deg) translateX(-2px) translateY(2px));
		}
		&::after {
			@include transform(rotate(-45deg) translateX(2px) translateY(2px));
		}
	}
}

// ---------

// TYPE LOCKUPS ---------------

.project-trail {
	width: 100%;
	.case & {
		width: 49.5%;
	}
	@include display(flex);
	@include flex-direction(row);
	position: relative;
	margin-bottom: 1.25em;
	margin-top: 2em;
	> li {
		width: 100%;
		margin-right: 6%;
		position: relative;
	}
}

.float-trail {
	@include display(flex);
	@include flex-direction(row);
	li {
		width: 100%;
		&:nth-child(1) {
			margin: 0 5% 0 0;
		}
		img {
			width: 50%;
			margin: 5% 0;
		}
	}
	&:not(.overflow-animate-wrap) {
		margin-bottom: 0.5em;
	}
}

.line-before {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 8px;
		right: 105%;
		width: 20px;
		height: 1px;
		background-color: $project-color;
	}
}

.next-prev-arrows {
	top: 50%;
	right: 3%;
	// @include transform(rotate(-45deg));
	margin-top: -5.8vw;
	width: 6vw;
	li {
		@include widthheightboth(6vw);
		// position: absolute;
		// left: 0;
		position: relative;

		a, a span, a span::before, a span::after {
			@include absolutefill();
		}
		a {
			@include transition(0.32s cubic-bezier($sweepinginout));
			span {
				overflow: hidden;
				@include transform(scale(0.4));

				&::before, &::after {
					content: '';
					-webkit-backface-visibility: hidden;
					background: url('/img/svg/arrow-01.svg') no-repeat center center / 100%;
				}
				&::before {
					@include transition(0.32s cubic-bezier($fluid) 0.2s);
				}
				&::after {
					// opacity: 0;
					@include transition(0.21s cubic-bezier($smootheaseout));
					@include transform(translateY(-105%));
				}

			}

			&:hover {
				span {
					// @include transform(translateY(18%));
					&::before {
						// opacity: 0;
						@include transform(translateY(105%));
						@include transition(0.25s cubic-bezier($smootheaseout));
					}
					&::after {
						opacity: 1;
						@include transform(translateY(0%));
						@include transition(0.55s cubic-bezier($fluid) 0.14s);
					}
				}
			}

		}
		&:nth-child(1) {
			@include transform(rotate(180deg));
			&::after {
				content: '';
				position: absolute;
				top: 0; left: 0;
				width: 100%;
				height: 1px; background-color: #FFF;
				@include transform(scaleX(0.5));
			}
		}
		&:nth-child(2) {
			// left: 5.5vw;
		}
	}
}

// END TYPE LOCKUPS

// .cursor-part {
// 	pointer-events: none;
// 	li {
// 		position: absolute;
// 		@include widthheightcentered(50px, 50px);
// 		border-radius: 100px;
// 		background-color: #000;
// 		@include transform(scale(0));
// 		@include transition(0.4s cubic-bezier($sweepinginout));

// 	}
// 	li:nth-child(1) {
// 		margin: -60px 0 0 -50px;
// 	}
// 	li:nth-child(2) {
// 		margin: 100px 0 0 -50px;
// 	}
// 	li:nth-child(3) {
// 		margin: -60px 0 0 -90px;
// 	}
// 	li:nth-child(4) {
// 		margin: -110px 0 0 130px;
// 	}
// 	li:nth-child(5) {
// 		margin: -200px 0 0 70px;
// 	}
// 	&.animate {
// 		li {
// 			@include animation(bubbles 0.5s forwards cubic-bezier($sweepinginout));
// 			@for $i from 1 through 10 {
// 			    &:nth-child(#{$i}) {
// 			    	$adelay: ($i*0.17-0.05s);
// 			        @include animation-delay($adelay);
// 			    }
// 			}
// 		}
// 	}
// }

.logo-wrap {
	top: 35px; left: 35px;

	@include transform-origin(0 0);
	@media only screen and (min-width: 1200px) {
		top: 40px; left: 40px;
	}
	@media only screen and (min-width: 1400px) {
		@include transform(scale(1.12));
		top: 44px; left: 44px;
	}
	@media only screen and (min-width: $large-desktop) {
		@include transform(scale(1.3));
		top: 50px; left: 50px;
	}
	> * {
		float: left;
		margin-right: 36px;
		margin-bottom: 0;
	}
	&:hover {
		&[data-logo-color="white"] {
			.logo-parts-hover {
				li {
					&::before {
						@include transform(scaleX(1));
					}
				}
			}
		}
		&[data-logo-color="black"] {
			.logo-parts-hover {
				li {
					&::after {
						@include transform(scaleX(1));
					}
				}
			}
		}
		.list-nav {
			li {
				@include transform(scaleX(1));
			}
		}
	}

	&[data-logo-color="white"] {
		.list-nav, .logo-parts {
			li {
				&::before {
					@include transform(scaleX(1));
				}
				&::after {
					@include transform(scaleX(0));
				}
			}
		}
		.logo-parts li {
			@for $i from 1 through 16 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.09s);
			    	&::before {
			    		@include transition-delay($tdelay);
			    	}

			    }
			}
		}
	}
	&[data-logo-color="black"], &[data-menu-active="active"] {
		.list-nav, .logo-parts {
			li {
				&::before {
					@include transform(scaleX(0));
					@include transition-delay(0s !important);
				}
				&::after {
					@include transform(scaleX(1));
				}
			}
		}
		.logo-parts li {
			@for $i from 1 through 16 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.09s);
			    	&::after {
			    		@include transition-delay($tdelay);
			    	}

			    }
			}
		}
	}
	&[data-menu-active="active"], &[data-active="off"] {
		pointer-events: none;
	}
	&[data-active="off"] {
		.list-nav, .logo-parts {
			li {
				&::before {
					@include transform(scaleX(0));
				}
				&::after {
					@include transform(scaleX(0));
				}
			}
		}
	}


}

ul.logo-parts, ul.logo-parts-hover {
	@include widthheight(36px, 36px);
	li {
		position: absolute;
		@include transform-origin(0 0);
		-webkit-backface-visibility: hidden;
		border-radius: 4px;
		&::before, &::after {
			-webkit-backface-visibility: hidden;
			content: '';
			@include widthheightboth(100%);
			position: absolute;
			top: 0; left: 0;
			@include transform-origin(0 0);
			@include transition(0.35s cubic-bezier($sweepinginout));
		}
		&::before {
			background-color: $bgwhite;
		}
		&::after {
			background-color: $nurture-black;
		}

	}
}

ul.logo-parts {
	position: relative;
	// overflow: hidden;
	@include transform-origin(0 0);
	li {
		$logohorizpartlength: 25%;
		$logostrokewidth: 2px;
		&:nth-child(1) {
			top: 0; left: 0;
			width: 35%;
			height: $logostrokewidth;
		}
		&:nth-child(2) {
			top: 0; left: 5%;
			@include transform(rotate(90deg));
			@include transform-origin(0 0);
			height: $logostrokewidth;
			width: 100%;
		}
		&:nth-child(3) {
			top: -1%; left: 5%;
			@include transform(rotate(45deg));
			width: 134%;
			height: $logostrokewidth;
		}
		&:nth-child(4) {
			bottom: 0; left: 0;
			width: $logohorizpartlength;
			height: $logostrokewidth;
		}
		&:nth-child(5) {
			@include transform(rotate(-33deg));
			bottom: 0; left: 29%;
			width: 16%;
			height: $logostrokewidth;
		}
		&:nth-child(6) {
			bottom: 12%;
		    left: 38%;
		    width: 2px;
		    height: 16%;
		}
		&:nth-child(7) {
			bottom: 0; left: $logohorizpartlength;
			width: $logostrokewidth;
			height: 44%;
		}
		&:nth-child(8) {
			@include transform(rotate(45deg));
			top: 0; left: 35%;
			width: 58%;
			height: $logostrokewidth;
		}
		&:nth-child(9) {
			@include transform(rotate(45deg));
			top: 56%;
		    left: 30%;
		    width: 55%;
			height: $logostrokewidth;
		}
		&:nth-child(10) {
			bottom: 0; left: 67%;
			width: 32%;
			height: $logostrokewidth;
		}
		&:nth-child(11) {
			bottom: 0; left: 96%;
			width: $logostrokewidth;
			height: 100%;
		}
		&:nth-child(12) {
			bottom: 55%; left: 73%;
			width: $logostrokewidth;
			height: 44%;
		}
		&:nth-child(13) {
			top: 0; left: 73%;
			width: $logohorizpartlength;
			height: $logostrokewidth;
		}


		&:nth-child(14) {
			top: 0;
		    right: 27%;
		    width: 17%;
		    height:$logostrokewidth;
		    @include transform(rotate(-33deg));
		    @include transform-origin(100% 0);
		}
		&:nth-child(15) {
			top: 9%;
		    right: 37%;
		    width: $logostrokewidth;
		    height: 19%;
		}


	}
	// &:hover {
	// 	li::before {
	// 		@include transform(scaleX(1));
	// 	}
	// }
}

.logo-parts-hover {
	overflow: hidden;
	position: absolute;
    top: 0;
    left: 0;
	li {
		&:nth-child(1) {
			bottom: -20%; left: 0;
			height: 30%;
			width: 90%;
			@include transform(rotate(-90deg) skewX(-45deg));
		}
		&:nth-child(2) {
			top: 0; left: 0;
			width: 130%;
			height: 20%;
			@include transform(rotate(45deg));
		}

		&:nth-child(3) {
			top: 0%;
    		right: -65%;
			width: 130%;
			height: 30%;
			@include transform(rotate(45deg) skew(-45deg));
		}
		&:nth-child(4) {
			bottom: -30%;
    		right: -75%;
			width: 100%;
			height: 24%;
			@include transform(rotate(-90deg));
		}
		&::before, &::after {
			@include transform(scaleX(0));
		}
		@for $i from 1 through 16 {
		    &:nth-child(#{$i}) {
		    	$tdelay: ($i*0.14s);
		    	&::before, &::after {
		    		@include transition-delay($tdelay);
		    	}

		    }
		}
	}
}

.list-nav {
	top: 50%;
    width: 30px;
    height: 23px;
    margin: -12px 0 0 -13px;

	overflow: hidden;
	display: inline-block;
	position: absolute;

	li {
		margin-left: -5%;
		width: 100%;
		height: 4px;
    	margin-top: -1px;
		position: absolute;
		left: 0;
		-webkit-backface-visibility: hidden;
		@include transform(scaleX(0.85));
		@include transform-origin(0 0);
		@include transition(0.35s cubic-bezier($sweepinginout));
		&::before, &::after {
			@include pseudofill();
			@include transform-origin(0 0);
			@include transition(0.45s cubic-bezier($sweepinginout));
		}
 		&::after {
			background-color: $nurture-black;
		}
		&::before {
			background-color: $bgwhite;
		}
		@for $i from 1 through 5 {
		    &:nth-child(#{$i}) {
		    	$tdelay: ($i*0.12-0.11s);
		    	@include transition-delay($tdelay);
		    	&::before, &::after {
		    		 @include transition-delay($tdelay);
		    	}

		    }
		}
		&:nth-child(1) {
			top: 6%;
		}
		&:nth-child(2) {
			top: 48%;
		}
		&:nth-child(3) {
			bottom: 0;
			@include transform(skew(-45deg) scaleX(0.7));
		}
	}
	&:hover {
		li {
			@include transform(scaleX(1));
		}
	}
}

.menu-indicator {
	body:not(.home) & {
		display: none;
	}
	position: absolute;
	left: 100%;
	height: 100%;
	margin-left: 30px;
	@include display(flex);
	@include justify-content(center);
	@include flex-direction(column);
	h4 {
		font-size: 11px;
		margin-bottom: 0;
		color: $bgwhite;
		&::before {
			display: none;
		}
	}
}

.nav-menu .menu-list ul li a::after, .small-link-before::after {
	content: '';
	width: 30%;
	height: 2px;
	background-color: $nurture-black;
	position: absolute;
	bottom: 0;
	@include transition(0.37s cubic-bezier($sweepinginout));
	@include transform(scaleX(0));
	@include transform-origin( 0 0 );
}

.nav-menu a.close, .home-experience-overlay-wrap a.close {
	@include widthheightboth(3.5vw);
	@media only screen and (max-width: $mobile-desktop) {
		@include widthheightboth(35px);
	}
	span {
		&::before, &::after {
			background-color: $nurture-black;
		}
	}
}

.nav-menu a.close {
	top: 50%;
	right: 3vw;

	@media only screen and (max-width: $mobile-desktop) {
		right: 8%;
	}
}

.nav-menu {
	@include widthheight(65%, 100%);
	margin-top: 0;
	> div {
		margin-top: 2.2em;
	}

	.menu-list ul {
		&:nth-child(1) {
			li:nth-child(1) {
				&::before {
					content: '01';
				}
			}
			li:nth-child(2) {
				&::before {
					content: '02';
				}
			}
		}
		&:nth-child(2) {
			li:nth-child(1) {
				&::before {
					content: '03';
				}
			}
			li:nth-child(2) {
				&::before {
					content: '04';
				}
			}
			li:nth-child(3) {
				&::before {
					content: '05';
				}
			}
		}
		li {
			position: relative;
			&::before {
				color: $nurture-black;
				position: absolute;
				top: 5px;
				left: -20px;
				@include transform(rotate(-90deg));
				font-size: 7px;
				font-weight: 800;
				text-align: right;
				font-family: "aktiv-grotesk";
				-webkit-backface-visibility: hidden;
			}

			a {
				font-size: 4vw;
				font-family: "abril-fatface";
				font-weight: normal;
				line-height: 1.3em;
				margin-bottom: 2.3vw;
				padding-bottom: 0.8vw;
				&::after {
					content: '';
					width: 30%;
					height: 2px;
					background-color: $nurture-black;
					position: absolute;
					bottom: 0;
					@include transition(0.37s cubic-bezier($sweepinginout));
					@include transform(scaleX(0));
					@include transform-origin( 0 0 );
				}
				&:hover {
					&::after {
						@include transform(scaleX(1));
					}
				}
			}
		}
	}
	.menu-list {
		width: 80%;
		margin: 0 0 0 15%;
		li {
			margin: 0 20% 0 0;
		}
	}
	.latest {
		width: 75%;
		margin: 3% 0 0 15%;
		li {
			margin: 0 6% 0 0;
			a {
				display: block;
			}
			&:last-child {
				width: 70%;
			    margin-right: 0;
			    margin-left: 5%;
			}
		}
	}
	overflow: hidden;
	@include transform(translateX(-100vw));
	@include transition(0.93s cubic-bezier($fluidless));
	background-color: rgb(250,250,250);
	// &::before {
	// 	@include pseudofill();
	// 	background-color: rgb(250,250,250);
	// 	@include transition(0.93s cubic-bezier($fluidless));
	// 	@include transform(translateX(-100vw));
	// }
	> * {
		@include transition(0.93s cubic-bezier($fluidless));
		@include transform(translateX(80vw));
	}
	pointer-events: none;
	&[data-active="on"] {
		pointer-events: auto;
		@include transform(translateX(0vw));
		> * {
			@include transform(translateX(0vw));
		}
		// &::before {
		// 	@include transform(translateX(0vw));
		// }
	}

	@media only screen and (max-width: 900px) {
		width: 100%;
		.latest {
			margin-top: 10%;
			li:not(:nth-child(3)) {
				display: none;
			}
			li:nth-child(3) {
				margin-left: 0;
				h4 {
					font-size: 0.75em;
				}
			}
		}
		.menu-list ul li a {
			font-size: 1.8em;
			h4 {
				display: none;
			}
		}
	}
}


.line-crumb {
	padding-right: 70px;
	margin-top: 0.4vw;
	&::after, &::before {
		content: '';
		position: absolute;
		width: 60px;
		right: 0;
		top: 50%;
		margin-top: 0px;
		height: 2px;
		@include transform(scaleX(0));
		@include transition(0.45s cubic-bezier($sweepinginout));
	}
	&::before {
		background-color: $nurture-black;
	}
	&::after {
		background-color: $bgwhite;
	}


	&[data-color="white"] {
		&::after {
			@include transform(scaleX(1));
		}
	}
	&[data-color="black"] {
		&::before {
			@include transform(scaleX(1));
		}
	}
}

.peripherals {
	pointer-events: none;
	li {
		@include transition(0.55s cubic-bezier($sweepinginout));
		position: absolute;
		&::before {
			@include pseudofill();
			@include transition(0.85s cubic-bezier($sweepinginout));
			// background-color: rgb(40,40,40);
			background-color: #000;
			// @include transform(scale(0, 0));
			@include transform-origin(0 0);
		}
		@for $i from 1 through 10 {
		    &:nth-child(#{$i}) {
		    	$tdelay: ($i*0.35-0.23s);
		    	// @include transition-delay($tdelay);
		    	&::before {
					@include transition-delay($tdelay);
				}
		    }
		}
	}
}

.line-hover {
	position: relative;
	display: table;
	&[data-color="white"] {
		color: $bgwhite;
	}
	&::after, &::before {
		content: '';
		@include widthheight(100%, 2px);

		position: absolute;
		bottom: -1px;
		left: 0;
		@include transition(0.28s cubic-bezier($sweepinginout));
		@include transform(scaleX(0));
		@include transform-origin(0 0);
		z-index: 99;
	}
	&::before {
		background-color: $bgwhite;
	}
	&::after {
		background-color: $nurture-black;
	}

	&:hover {
		&::after {
			@include transform(scaleX(1));
		}
		&[data-color="white"] {
			&::after {
				@include transform(scaleX(0));
			}
			&::before {
				@include transform(scaleX(1));
			}
		}

	}
}

.a-icon {
	// padding-right: 18px;
	span {
		@include widthheightboth(8px);
		margin-right: -16px;
		margin-top: -4px;
		right: 0;
		top: 50%;
		content: '';
		position: absolute;

		&::before {
			@include pseudofill();
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	&.icon-l {
		span {
			margin-left: -16px;
			left: 0;
			right: auto; margin-right: 0;
		}
	}
	&.icon-all-cases {
		span::before {
			background-image: url('/img/svg/icon-all-cases.svg');
		}
	}
	&.icon-play {
		span::before {
			background-image: url('/img/svg/icon-play.svg');
		}
	}
	&.icon-plus {
		span {
			width: 10px;
		    height: 10px;
		    margin-right: -20px;
		    margin-top: -5px;
		    @include transition(0.35s);
		}
		span::before {
			background-image: url('/img/svg/icon-plus.svg');
		}
		&:hover {
			span {
				@include transform(rotate(90deg));
			}
		}
	}
	&.icon-arrow {
		span {
			@include animation(arrowdown 1s infinite alternate cubic-bezier($fluidless));
		}
		span::before {
			background-image: url('/img/svg/arrow-white-thick.svg');
			@include transform(scale(1.5));
		}
		&[data-color="black"] span::before {
			background-image: url('/img/svg/arrow-black-thick.svg');
			@include transform(scale(1.5));
		}
	}
}

.social-nav {
	// right: 40px; top: 40px;
	right: 2.8vw;
    top: 2.4vw;
	h5, ul li {
		@include transition(0.45s cubic-bezier($sweepinginout));
		@for $i from 1 through 7 {
		    &:nth-child(#{$i}) {
		    	$tdelay: ($i*0.09+0.5s);
		    	@include transition-delay($tdelay);
		    }
		}
	}

	&[data-active="off"] {
		h5, ul li {
			@include transform(translateY(-70px));
		}
		.mute-icon::before {
			@include transform(scaleY(0));
		}
		.hire-us
		{
			width: 0;
		}
	}

	h5, ul, > a {
		float: left;
	}

	li a, > a {
		@include widthheightboth(2vw);
		max-width: 24px;
    	max-height: 24px;
	}

	.mute-icon {
		margin-left: 40px;
		&::before {
			content: '';
			width: 1px;
			height: 100%;
			position: absolute;
			left: -20px;
			top: 0;
			@include transition(0.45s cubic-bezier($sweepinginout));
			background-color: $nurture-black;
			@include transform(scaleY(0.6));
		}
	}

	.hire-us {

		$width: 65px;
		$height: 19px;

		&, & * { box-sizing: border-box; }

		margin-top: 4px;
		max-width: none;
		width: 65px;
		height: 19px;
		font-size: 9px;
		font-weight: bold;
		margin-left: 20px;
		display: inline-block;
		white-space: nowrap;
		text-transform: uppercase;
		overflow: hidden;
		position: relative;

		@include transition(width .28s cubic-bezier($sweepinginout));

		> .content
		{
			display: block;
			position: absolute;
			top: 0;
			left: 0;

			> span
			{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: $width;
				height: $height;

				> .content
				{
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: $width;
					height: $height;
					line-height: 0;
					padding-top: 8.5px;
					text-align: center;
					border: 1px solid $nurture-black;
				}

				&.dark
				{
					overflow: hidden;
					left: -$width;

					> .content
					{
						background: $nurture-black;
						color: $bgwhite;
						left: $width;
					}

					&,
					> .content
					{
						@include transition(left .28s cubic-bezier($sweepinginout));
					}
				}
			}
		}

		&:hover
		{
			> .content
			{
				> span
				{
					&.dark
					{
						&,
						> .content
						{
							left: 0;
						}
					}
				}
			}
		}

		body.hire-us &
		{
			display: none;
		}
	}
}

.disclaimer {
	// right: 22px; bottom: 20px;
	right: 2.8vw;
    bottom: 2.4vw;
}

// HOME LETTER STUFF

.type-wrap {
	left: 59%;
	width: 30%;
	height: 100%;
	z-index: 10;

	.flex-vert-center > *:not(.clickable) {
		pointer-events: none;
	}
	&[data-home-state="video-full"], &[data-active="off"] {
		pointer-events: none;
		.overflow-animate-wrap > * {
			@include transform(translate3d(0,150%,0));
		}
	}
}

.cta-wrap {
	width: 25%;
	@include transform(translateY(-50%));
	a {
		display: block;
		float: right;
    	clear: both;
		@include transition(0.28s cubic-bezier($sweepinginout));
		@include transform-origin( 0 0 );
	}
	&.overflow-animate-wrap, &.overflow-animate-wrap div {
		padding-right: 20px; margin-right: -20px;
	}
	&.overflow-animate-wrap > div {
		overflow: hidden;
		@include transition-delay(0.5s);
	}
}

.cta-wrap[data-home-state="video-full"], body[data-home-front-state="front"] .cta-wrap {
	pointer-events: none;
	> div {
		@include transform(translate3d(0,150%,0));
	}
}

h5.corner-crumb {
	position: absolute;
	bottom: 2.7vw;
	right: 2.9vw;
	@media only screen and (max-width: $mobile-desktop) {
		bottom: 22px;
		right: 26px;
	}
	-webkit-backface-visibility: hidden;
	text-align: right;
	white-space: nowrap;
	margin-bottom: 0;
	z-index: 10;
	pointer-events: none;
	@include transform(rotate(90deg));
	@include transform-origin(100% 100%);
	&::before, &::after {
		@include transition(0.45s cubic-bezier($sweepinginout));
		@include transform-origin(100% 100%);
		right: 112%;
	}

	&::after {
		position: absolute;
		top: 0%;

		content: '01';
		text-align: right;
		font-size: 8px;
		font-weight: 700;
		padding-right: 40px;
	}

	&::before {
		position: absolute;
		top: 45%;
		content: '';
		height: 2px;
		width: 30px;
		background-color: $nurture-black;
	}
	&.corner-crumb-left {
		right: auto;
		left: 2.7vw;
		@include transform(rotate(-90deg));
		@include transform-origin(0% 100%);
		text-align: left;
		&::before, &::after {
			right: auto;
			left: 112%;
			text-align: left;
		}
		&::after {
			padding-right: 0;
			padding-left: 40px;
		}
	}
}

.open-case {
	margin-top: 20px;
}

// VIDEO MASKS

.full-screen-transition {
	@include transition(0.85s cubic-bezier($sweepinginout));
	background-color: #000;
	z-index: 100;
	@include transform-origin(0 0);
	@include transform(scaleX(0));
	&[data-active="on"] {
		@include transform(scaleX(1));
		&:hover {
			// cursor: pointer;
			// cursor: w-resize;
			cursor: none;
		}
	}
}

// .wrapper[data-state="2"] .overflow-l-p-1 {
// 	@include transform(translateY(62.5%));
// 	.part {
// 		@include transform( translateY(-50%));

// 		&::before {
// 			@include transform(scale(0.84) );
// 		}
// 		&::after {
// 			@include transform(scale(0.24) );
// 		}
// 	}
// }

// .wrapper[data-state="2"] {
// 	background-color: #AAC9E2;
// }

// .wrapper[data-state="3"] .l-p-1 {
// 	@include transform( scaleY(1) translateX(-27%) scaleX(0.3));
// }

// .wrapper[data-state="3"] .l-p-2 {
// 	@include transform( skewX(30deg) translateY(24.6%) translateX(-10%));
// 	&::before {
// 		@include transform( scaleY(0.5) scaleX(0.32) );
// 	}
// }

// .wrapper[data-state="3"] .overflow-l-p-1 {
// 	@include transform(translateY(-11%) translateX(50%));
// 	.part {
// 		@include transform( translateX(-50%));

// 		&::before {
// 			@include transform(scale(0.75) );
// 		}
// 		&::after {
// 			@include transform(scale(0.24) );
// 		}
// 	}
// }

.left-right {
	li {
		width: 100%;
		height: 100%;
		position: relative;
		// background-color: red;

		&::before {
			content: '';
			position: absolute;
			top: 49.9%;
			left: 0;
			@include widthheight(100%, 1px);
			background-color: rgba(#fff,1);
			@include transform(scaleX(0));
			@include transform-origin(0 0);
			@include transition(0.35s cubic-bezier($sweepinginout));
		}
		&:nth-child(2) {
			&::before {
				@include transform-origin(100% 0);
			}
		}

		&:hover {
			&::before {
				@include transform(scaleX(0.06));
			}
		}
	}
}

.yt-player {
	width: 100%; height: 100%;
	@include transform( scale(1.15));

}

.js-yt-player {
	opacity: 1;
	pointer-events: none;
	@include transition(0.15s linear);

	&[data-active="off"] {
		opacity: 0;
		@include transition(0.15s linear);
	}
}

.player-timeline {
	height: 1px;
	left: 5vw;
	bottom: 5vw;
	@include transition(0.55s cubic-bezier($sweepinginout));
	@include transform-origin( 0 0 );
	&::before {
		content: '';
		@include transform-origin( 0 0 );
		position: absolute;
		@include widthheightboth(100%);
		background-color: rgba(255,255,255,0.4);
		@include transition(0.85s cubic-bezier($sweepinginout));
	}
	.current-point {
		@include widthheightboth(100%);
		width: 100%;
		.current-timeline {
			position: absolute;
			@include widthheight(100%, 2px);
			top: -1px;
			@include transform(scaleX(0.5));
			@include transform-origin(0 0);
			@include transition(1s cubic-bezier($sweepinginout));
			background-color: #fff;
		}
		.current-node {
			position: absolute;
			top: -4px;
			left: -4px;
			@include widthheightboth(8px);
			@include transition(1s cubic-bezier($sweepinginout));
			span {
				position: absolute;
				top: 0; left: 0;
				@include widthheightboth(100%);
				background-color: #fff;
				@include transition(0.55s cubic-bezier($sweepinginout) 0s);
				@include transform(scale(1));
				border-radius: 10px;

			}
		}
	}
	.time-points {
		width: 100%;
		li {
			left: 0%;
			@include widthheightboth(6px);
			margin: -3px 0 0 -3px;
			position: absolute;
			border-radius: 10px;
			background-color: #FFF;
			@for $i from 1 through 5 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.1s);
			    	$tduration: ($i*0.15+0.55s);
			        @include transition($tduration cubic-bezier($sweepinginout) $tdelay);
			    }
			}
		}
	}
	h1 {
		bottom: 1vw; left: 0vw;
		color: $bgwhite;
	}
	h3 {
		bottom: 1vw; right: 0vw;
		color: $bgwhite;
		@include transform(translateX(50%));
	}

}

.timeline-skipper, .player-timeline {
	right: calc(5vw + 80px);
}

.timeline-skipper {
	z-index: 15;
	left: 5vw;
	bottom: 4vw;
	height: 2vw;
	display: none;
	&:hover {
		cursor: pointer;
	}
}



.timeline-captions {
	left: 5vw;
	top: 40%;
	width: 25%;
	line-height: 1.4em;
	font-family: "abril-display";
	@include transition(0.55s cubic-bezier($sweepinginout));
	li {
		position: absolute;
		top: 0; left: 0;
		color: $bgwhite;
		h4 {
			color: $bgwhite;
		}
		span {
			@include transform(translateY(105%));
		}
	}
	&[data-active-vid-caption="1"] li:nth-child(1) span,
	&[data-active-vid-caption="2"] li:nth-child(2) span,
	&[data-active-vid-caption="3"] li:nth-child(3) span,
	&[data-active-vid-caption="4"] li:nth-child(4) span,
	&[data-active-vid-caption="5"] li:nth-child(5) span, {
		@include transform(translateY(0));
	}
}

.fixed-elements {
	> * {
		position: fixed;
		@include transition(0.45s cubic-bezier($sweepinginout));
	}
	.video-takeover {
		left: 31%;
		top: 4vw;
	}
	.close {
		top: 50%; right: 3.5vw;
		z-index: 20;
		@include transform(scale(0));
	}
	.head-scroll, .skip-start, .timeline-skip-pause {
		@include transform(scale(0));
		@include transform-origin(100% 50%);
	}
	&[data-case-vid-state="full"], &[data-case-vid-state="left"] {
		.case-scroll-full {
			@include transform(translateX(300px));
		}
	}
	&[data-case-vid-state="full"] {
		.head-scroll, .skip-start, .timeline-skip-pause {
			@include transform(scale(1));
		}
		.timeline-skipper {
			display: block;
		}
	}
	&[data-screen-state="video-full"] {
		.close, .full-screen-transition, .skip-start, .timeline-skip-pause {
			@include transform(scale(1));
		}
		.timeline-skipper {
			display: block;
		}
		.case-scroll, .next-prev-arrows {
			@include transform(scale(0));
		}
		.case-scroll-full {
			@include transform(translateX(300px));
		}
		.footer > * {
			@include transform(translateY(130%));
		}


	}
	&[data-screen-state="gallery"], &[data-case-vid-state="right"], &[data-case-vid-state="full"] {
		.close, .case-scroll, .video-takeover-panel {
			@include transform(scale(0));
		}
	}
	.footer {
		bottom: 2.5vw;
		left: 4vw;
		> * {
			float: left;
			margin-right: 10px;
			@for $i from 1 through 8 {
			    &:nth-child(#{$i}) {
			    	$tdelay: ($i*0.13s);
			        @include transition(0.35s cubic-bezier($sweepinginout) $tdelay);
			    }
			}
		}
	}
}

.black-bg {
	background-color: $nurture-black;
	@include all-text-white();
}

.white-text {
	@include all-text-white();
}

.page-nav {
	top: 50%;
	left: 0;
	@include widthheightvertcenter(2.5%, 30vh);
	&.long {
		@include widthheightvertcenter(1%, 50vh);
	}

	@include display(flex);
	@include justify-content(space-between);
	@include flex-direction(column);
	li {

		width: 100%;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			@include widthheight(100%, 2px);
			@include transform(scaleX(0.6));
			@include transform-origin(0 0);
			@include transition(0.73s cubic-bezier($sweepinginout));
			background-color: rgba(210,210,210,0.8);
		}

	}
	&[data-page-nav-active="1"] li:nth-child(1)::before,
	&[data-page-nav-active="2"] li:nth-child(2)::before,
	&[data-page-nav-active="3"] li:nth-child(3)::before,
	&[data-page-nav-active="4"] li:nth-child(4)::before,
	&[data-page-nav-active="5"] li:nth-child(5)::before,
	&[data-page-nav-active="6"] li:nth-child(6)::before,
	&[data-page-nav-active="7"] li:nth-child(7)::before,
	&[data-page-nav-active="8"] li:nth-child(8)::before,
	&[data-page-nav-active="9"] li:nth-child(9)::before,
	&[data-page-nav-active="10"] li:nth-child(10)::before,
	&[data-page-nav-active="11"] li:nth-child(11)::before,
	&[data-page-nav-active="12"] li:nth-child(12)::before,
	&[data-page-nav-active="13"] li:nth-child(13)::before,
	&[data-page-nav-active="14"] li:nth-child(14)::before,
	&[data-page-nav-active="15"] li:nth-child(15)::before {
		@include transform(scaleX(1));
	}
}

.overflow-animate-wrap {
	overflow: hidden;
	padding-left: 10%; margin-left: -10%;
	p {
		margin-bottom: 0;
		padding-bottom: 1.8em;
	}
	> * {
		@include transition(0.65s cubic-bezier($smootheaseout));
		@include transform(translate3d(0,0,0));
		display: block;
		&.line-hover {
			display: table;
		}
	}
	@for $i from 1 through 10 {
	    &:nth-child(#{$i}) > * {
	    	$tdelay: ($i*0.12-0.12s);
	        @include transition-delay($tdelay);
	    }
	}

}
[data-overflow-state="inactive"] {
	> * {
		@include transform(translate3d(0,140%,0));
	}
}

.grid {
	pointer-events: none;
	position: fixed;
	top: 0; left: 0;
	@include widthheightboth(100%);
	ul {
		left: 0;
		@include widthheightboth(100%);
	}
	ul, li {
		position: absolute;
		top: 0;
	}
	li {
		margin-left: -1px;
		height: 100%; width: 1px;
		&::before {
			content: '';
			@include widthheightboth(100%);
			background-color: rgba(0,0,0,0.06);
			position: absolute; left: 0; top: 0;
		}
	}
	ul {
		@include transform(scaleY(1));
		@include transform-origin(0 0);
		@include transition(0.4s cubic-bezier($sweepinginout));
	}
	&[data-active="off"], &[data-screen-state="video-full"] {
		ul {
			@include transform(scaleY(0));
		}
	}
}

// MOBILE STYLESHEET

@media only screen and (max-width: $mobile-desktop) {
	.project-trail {
		width: 90%;
	}

	.fixed-elements > * {
		display: none;
	}
}

@include keyframes(galleryhover) {
	0% {
		background-color: rgba(#fff,0);
	}
	25% {
		background-color: rgba(#fff,0.05);
	}
	100% {
		background-color: rgba(#fff,0);
	}
}

@include keyframes(arrowdown) {
	0% {
		@include transform(translateY(-30%));
	}
	100% {
		@include transform(translateY(30%));
	}
}

@include keyframes(rotateforever) {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}

//SCROLLBARS
// ::-webkit-scrollbar {
// 	height: 12px;
// 	width: 9px;
// 	background: rgba(0,0,0,0);
// 	border-radius: 20px;
// }

// ::-webkit-scrollbar-thumb {
// 	background:$threat-red;
// 	border: 3px solid #232323;
// 	border-radius: 20px;
// }

::selection {
  background: #181818; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: #181818; /* WebKit/Blink Browsers */
  color: #fff;
}




#ouibounce-modal
{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2147483647;
	background: rgba(0, 0, 0, .3);
	visibility: hidden;

	&.show
	{
		visibility: inherit;
	}

	.content
	{
		position: absolute;
		left: 50%;
		top: 50%;
		width: 412px;
		margin-left: -206px;
		min-height: 570px;
		margin-top: -285px;
		background: white;
		padding: 30px 50px 0px;
    	box-sizing: border-box;

		.close{
			position: absolute;
		    right: 16px;
		    top: 37px;
			width: 35px;
			span {
				&::before, &::after {
					content: '';
					width: 100%;
					height: 2px;
					background-color: black;
				}
				&:nth-child(1) {
					&::before, &::after {
						background-color: black;
						}
				}
			}
		}


		h1{
			// font-size: 4vw;
			font-size: 4.3em;
		}

		p{
			// font-size: 1.1vw;
			font-size: 1.15em;
			font-family: "abril-display";
			line-height: 1.3em;
			font-weight: normal;
			letter-spacing: -0.01em;
			margin-bottom: 30px;
			width: 90%;

			strong{
				text-decoration: none;
			}
		}

		p.bottom-text {
			// font-size: .9vw;
			font-size: .95em;
			width: 100%;
		}
		.form{
			margin-bottom: 40px;
		}
		#mc_embed_signup{

			#mc-embedded-subscribe-form{
				padding: 0;
				text-align: right;

				#mc_embed_signup_scroll{

					.email{
						display: block;
						width: 100%;
						padding: 1.5em;
						box-sizing: border-box;
						background: transparent;
						border: 3px solid #27282e;
						border-radius: 0;
						outline: none;
						font-family: "aktiv-grotesk";
						font-size: .8em;
						text-transform: uppercase;
						font-weight: bold;
						letter-spacing: 1px;
					}
					.mc-field-group{

						text-align: left;
						width: 100%;
						margin-bottom: 1em;

						label{
							display: none;
						}
						input{
							margin-top: 0;
						}

						.mce_inline_error{
							display: block;
						    width: 100%;
						    // padding: 1.5em;
						    box-sizing: border-box;
						    background: transparent;
						    outline: none;
						    font-family: "aktiv-grotesk";
						    font-size: .8em;
						    text-transform: uppercase;
						    font-weight: bold;
						    letter-spacing: 1px;
						    color: #991111;
							margin-top: 10px;
							margin-bottom: 0;
						}
					}
					.cta{
						background-color:#27282e;

					}
					.cta-full{
						&::before, &::after {
							background-color: black;
						}
					}
					.submit-button{
						display: inline-block;
					    width: auto;
					    cursor: pointer;
					    padding: 0;
					    font-size: 1em;
						border-color: #27282e;

						input{
							display: block;
							margin: -3px;
						    width: auto;
						    cursor: pointer;
							color: #27282e;
							padding: 1em 1.8em 3.6em;
							box-sizing: border-box;
							border: 3px solid #27282e;
							border-radius: 0;
							outline: none;
							font-family: "aktiv-grotesk";
							font-size: .8em;
							text-transform: uppercase;
							font-weight: bold;
							letter-spacing: 1px;
						}
					}
					.button{
						background: white;
					}
					#mce-responses{
						margin: 0;
						text-align: left;
						top:10px;
						padding: 0;
						width: 95%;
						#mce-success-response, #mce-error-response{
							width: 100%;
							margin-bottom: 0;
							font-family: "aktiv-grotesk";
							font-size: .7em;
							text-transform: uppercase;
							font-weight: bold;
							letter-spacing: 1px;
							line-height: 1.25em;
						}
					}
				}
			}



		}
	}

}
